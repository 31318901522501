"use strict";

function tryFullScreen(){
	var el = document.documentElement,
		rfs = el.requestFullscreen
			|| el.webkitRequestFullScreen
			|| el.mozRequestFullScreen
			|| el.msRequestFullscreen
		;

	rfs.call(el);
}


(function(){
	// Initialize Firebase
	var fireBaseConfig = {
		apiKey: "AIzaSyDF4lw-Cr6pDf1IW_xMqqTVnxE3KM6VAso",
		authDomain: "garces-mena.firebaseapp.com",
		databaseURL: "https://garces-mena.firebaseio.com",
		projectId: "garces-mena",
		storageBucket: "garces-mena.appspot.com",
		messagingSenderId: "22683744282"
	};
	firebase.initializeApp(fireBaseConfig);

	var luxenceAppModule = angular.module("luxenceApp", ["firebase", "hmTouchEvents", "ngSanitize", 'angularModalService'])

		.config(["$firebaseRefProvider", appConfig])

		.controller("MainWrp", ["$scope", "$location", "$anchorScroll", MainController])
		.controller("PropsListController", [ "$scope", "$filter", "PropertiesFactory", "TypesOfPropsFactory", "NeighborhoodsFactory", "$sce", "lxaPreloader", "$timeout", "AlertMessage", PropsListController ])

		.factory("PropertiesFactory", ["$firebaseArray", "$firebaseRef", PropertiesFactory])
		.factory("TypesOfPropsFactory", ["$firebaseObject", "$firebaseRef", TypesOfPropsFactory])
		.factory("NeighborhoodsFactory", ["$firebaseArray", "$firebaseRef", NeighborhoodsFactory])
		.factory("lxaPreloader", ["$q", "$rootScope", PreloaderFactory])
		.service("AlertMessage", ["ModalService", AlertService])

		.filter("asNeighborhoodName", ["NeighborhoodsFactory", asNeighborhoodName])
		.filter('isInPriceRange', isInPriceRange)
		.filter('isLandInSizeRange', isLandInSizeRange)
		.filter('isBuildingInSizeRange', isBuildingInSizeRange)
		.filter('isInNeighborhood', isInNeighborhood)
		.filter('isType', isType)
		.filter('isOnline', isOnline)
		.filter('noNullFeatures', noNullFeatures)
		.filter('favoritesProps', favoritesProps)

		.directive("lxaScrollNavPropertyList", ['$document', '$window', '$interval', '$timeout', lxaScrollNavPropertyList])
		.directive("lxaProperty", ["$window", lxaProperty])
		.directive("lxaTypeSelector", ["AlertMessage", lxaTypeSelector])
		.directive("lxaTypeSelectorOption", lxaTypeSelectorOption)
		.directive('lxaMultiSelect', ["$interval", lxaMultiSelect])
		.directive('lxaMatterportLoader', ["$timeout", lxaMatterportLoader])
		.directive('lxaVideoLoader', lxaVideoLoader)
		.directive('lxaTouchClasses', lxaTouchClasses);

	// Config
	function appConfig($firebaseRefProvider){
		$firebaseRefProvider.registerUrl({
			default: fireBaseConfig.databaseURL,
			properties: fireBaseConfig.databaseURL + "/properties",
			typesOfProps: fireBaseConfig.databaseURL + "/typesOfProperties",
			neighborhoods: fireBaseConfig.databaseURL + "/neighborhoods"
		});
	}

	/* CONTROLLERS */
	function MainController($scope, $location, $anchorScroll){

		$scope.status = "preloading";
		$scope.hidePreloader = false;
		$scope.tutorialIsVisible = false;
		$scope.mainNavVisible = false;
		$scope.connectedToInternet = false;

		$scope.$on("$locationChangeSuccess", function () {
			$scope.connectedToInternet = $location.hash() != "internetIsOff";
		});

		$scope.toggleMainNav = function(){
			$scope.mainNavVisible = !$scope.mainNavVisible;
		};

		$scope.changeStatus = function($status){
			$scope.status = $status;
		};

		$scope.hideNextTutorials = function(){
			$scope.tutorialIsVisible = false;
			localStorage.setItem("hideTutorial", "hideTutorial");
		};

		$scope.clickInMainNavBtn = function($status){
			$scope.changeStatus($status);
			$scope.toggleMainNav();
		};

		$scope.goToHash = function($anchorId) {
			$location.hash($anchorId);
			$anchorScroll();
		};


	}
	function PropsListController($scope, $filter, PropertiesFactory, TypesOfPropsFactory, NeighborhoodsFactory, $sce, lxaPreloader, $timeout, AlertMessage){
		$scope.screenResolution = "";

		$scope.currentProperty = 0;
		$scope.currentPage = 0;
		$scope.totalPages = 1;

		$scope.updateFilteredProps = updateFilteredProps;

		$scope.allTypes = TypesOfPropsFactory;
		$scope.allNeighborhoods = NeighborhoodsFactory;
		$scope.allProps = PropertiesFactory;
		$scope.allProps.$loaded().then(function(){
			$scope.allProps = $filter("isOnline")($scope.allProps);
			propsLoaded();
		});

		$scope.showFavoritesBtnClicked = showFavoritesBtnClicked;
		$scope.hideFavorites = hideFavorites;

		$scope.favorites = [];

		if(localStorage.getItem("favs")!="" && localStorage.getItem("favs")!=null){
			$scope.favorites = JSON.parse(localStorage.getItem("favs"));
		}

		$scope.filterFormValues = {
			minPrice:			0,
			maxPrice:			1000000000,
			minLandSize:		0,
			maxLandSize:		100000,
			minBuildingSize:	0,
			maxBuildingSize:	100000,
			neighborhood:	   	["FDP", "CYN", "JDP", "FIA", "LDH", "NAR"],
			types:				{"C":"Casa", "D":"Departamento", "DI":"Desarrollo inmobiliario", "O":"Oficinas", "T":"Terreno"}
		};

		$scope.getSecureUrl = function(src){
			return $sce.trustAsResourceUrl(src);
		};

		$scope.onCancelFilterBtnClick = function(){
			if($scope.filteredProps.length>0){
				$scope.$parent.changeStatus("showPropertyList");
			}else{
				AlertMessage.show("No hay propiedades que coincidan con tu búsqueda, intentalo de nuevo.");
			}
		};

		$scope.onFilterBtnClick = function(){
			updateFilteredProps();

			if($scope.filteredProps.length>0){
				$scope.$parent.changeStatus("showPropertyList");
			}else{
				AlertMessage.show("No hay propiedades que coincidan con tu búsqueda, intentalo de nuevo.");
			}
		};

		$scope.onReadMoreBtnClick = function(){
			$scope.$parent.changeStatus("showFullDescription");
		};

		$scope.onCloseReadMoreClick = function(){
			$scope.$parent.changeStatus("showPropertyList");
		};

		$scope.addToFavs = function ($id){
			$scope.favorites.push($id);
			saveFavs();
		};

		$scope.removeFromFavs = function($id){
			$scope.favorites.splice($scope.favorites.indexOf($id), 1);
			saveFavs();

			if($scope.$parent.status=="favoritesListShowing"){
				if($scope.favorites.length<1){
					hideFavorites();
				}else{
					$scope.updateFavoritesProps();
				}
			}
		};

		$scope.updateFavoritesProps = function(){
			var timeBeforeGoUp;

			$scope.filteredProps = $filter('favoritesProps')($scope.allProps, $scope.favorites);

			if ($scope.currentProperty <= $scope.favorites.length-1){
				timeBeforeGoUp = 2100;
			}else{
				timeBeforeGoUp = 200;
			}

			$timeout(function(){
				$scope.currentProperty = 0;
				$scope.$broadcast("filteredPropsHasChanged");
			}, timeBeforeGoUp);

		};

		$scope.showPage = function($pageNumber){
			$scope.currentPage = $pageNumber;
		};

		function saveFavs(){
			localStorage.setItem("favs", JSON.stringify($scope.favorites));
		}

		function showFavorites(){
			$scope.$parent.changeStatus("showFavoritesTransition");

			$timeout(function(){
				$scope.$parent.changeStatus("favoritesListShowing");
			}, 2100);

			$scope.updateFavoritesProps();
		}

		function hideFavorites(){
			$scope.$parent.changeStatus("showPropertyList showFavoritesTransition");

			$timeout(function(){
				$scope.$parent.changeStatus("showPropertyList");
			}, 2100);

			updateFilteredProps();
		}

		function showFavoritesBtnClicked(){
			if($scope.favorites.length<1){
				AlertMessage.show("No tienes propiedades guardadas.");
			}else{
				showFavorites();
			}
		}

		function propsLoaded(){

			var imagesToPreLoad = [];

			for(var i=0; i<$scope.allProps.length; i++){
				imagesToPreLoad.push($scope.allProps[i].coverPhoto);
			}

			lxaPreloader.preloadImages(imagesToPreLoad).then(hidePreLoaderAndShowProps,hidePreLoaderAndShowProps);

		}

		function hidePreLoaderAndShowProps(){

			$scope.filteredProps = $scope.allProps;

			$scope.screenResolution = (window.innerHeight/window.innerWidth)>0.5625? "narrower": "wider";

			$timeout(function(){
				$scope.$parent.changeStatus("showWhoAreWe");

				if(localStorage.getItem("hideTutorial")!="hideTutorial"){
					showTutorial();
				}
				$timeout(function(){
					$scope.$parent.hidePreloader = true;
				}, 150);
			//TODO: ajustar este timeout para que no aparezcan imagenes cargandose
			}, 500);
		}

		function updateFilteredProps(){
			$scope.filteredProps = $filter("isInPriceRange")($scope.allProps, $scope.filterFormValues.minPrice, $scope.filterFormValues.maxPrice);
			$scope.filteredProps = $filter("isLandInSizeRange")($scope.filteredProps, $scope.filterFormValues.minLandSize, $scope.filterFormValues.maxLandSize);
			$scope.filteredProps = $filter("isBuildingInSizeRange")($scope.filteredProps, $scope.filterFormValues.minBuildingSize, $scope.filterFormValues.maxBuildingSize);
			$scope.filteredProps = $filter("isInNeighborhood")($scope.filteredProps, $scope.filterFormValues.neighborhood);
			$scope.filteredProps = $filter("isType")($scope.filteredProps, $scope.filterFormValues.types);


			$scope.currentProperty = 0;
			$scope.$broadcast("filteredPropsHasChanged");
		}

		function showTutorial(){
			$scope.$parent.tutorialIsVisible = true;

			$timeout(function(){
				$scope.$parent.animationStatus = "swipeForDetails";
				$timeout(function(){
					$scope.$parent.animationStatus = "swipeForProperties";
					$timeout(function(){
						$scope.$parent.animationStatus = "endTutorial";
						$timeout(function(){
							$scope.$parent.animationStatus = "tutorialEnded";
							//localStorage.setItem("hideTutorial", "hideTutorial");
						}, 700);
					},8000);
				},8000);
			}, 200);
		}
	}
	/* FACTORIES */
	function PropertiesFactory($firebaseArray, $firebaseRef){
		return $firebaseArray($firebaseRef.properties);
	}
	function TypesOfPropsFactory($firebaseObject, $firebaseRef){
		return $firebaseObject($firebaseRef.typesOfProps);
	}
	function NeighborhoodsFactory($firebaseArray, $firebaseRef){
		return $firebaseArray($firebaseRef.neighborhoods);
	}
	function AlertService(ModalService){

		this.show = function(msg){
			ModalService.showModal({
				templateUrl: "templates/lxa-modal.html",
				controller: ["$scope", "close", function($scope, close) {
					$scope.message = msg;
					$scope.closeLabel = "Aceptar";
					$scope.dismissModal = function() {
						close();
					};
				}]
			});
		};


	}
	/* FILTERS */
	function asNeighborhoodName(NeighborhoodsFactory){
		return function(neighborhoodCode){

			var neighborhoodToReturn;

			for(var i=0; i<NeighborhoodsFactory.length; i++){
				if(NeighborhoodsFactory[i].code == neighborhoodCode){
					neighborhoodToReturn = NeighborhoodsFactory[i].label;
					break;
				}
			}

			return neighborhoodToReturn;
		}
	}
	function isInPriceRange() {
		return function (items, min, max) {
			var filtered = [];
			for (var i = 0; i < items.length; i++) {
				var item = items[i];
				if (item.price>=min && item.price<=max) {
					filtered.push(item);
				}
			}
			return filtered;
		};
	}
	function isLandInSizeRange() {
		return function (items, min, max) {
			var filtered = [];
			for (var i = 0; i < items.length; i++) {
				var item = items[i];
				if (item.mainFeatures.landSqMeters>=min && item.mainFeatures.landSqMeters<=max) {
					filtered.push(item);
				}
			}
			return filtered;
		};
	}
	function isBuildingInSizeRange() {
		return function (items, min, max) {
			var filtered = [];
			for (var i = 0; i < items.length; i++) {
				var item = items[i];
				if (item.mainFeatures.builtSqMeters>=min && item.mainFeatures.builtSqMeters<=max) {
					filtered.push(item);
				}
			}
			return filtered;
		};
	}
	function isInNeighborhood(){
		return function(items, neighborhoods){
			var filtered = [];

			for(var i=0; i<items.length; i++){
				var item = items[i];
				if(neighborhoods.indexOf(item.neighborhood)!=-1){
					filtered.push(item);
				}
			}

			return filtered;
		}
	}
	function isType(){
		return function(items, types){
			var filtered = [];

			for(var i=0; i<items.length; i++){
				var item = items[i];
				if(item.type in types){
					filtered.push(item);
				}
			}

			return filtered;
		}
	}

	function isOnline(){

		return function(items){
			var filtered = [];

			for(var i=0; i<items.length; i++){
				var item = items[i];

				if(i==0){
					//console.log(JSON.stringify(item));
				}

				if(item.online == true){
					filtered.push(item);
				}
			}

			return filtered;
		}
	}
	function noNullFeatures(){

		var labels = {
			airConditioning:"Aire acondicionado",
			balcony:"Balcón",
			centralHeating:"Calefacción",
			coveredParking:"Estacionamientos cubiertos",
			driversRoom:"Cuarto de choferes",
			familyRoom:"Family room",
			firepit:"Pozo de fuego",
			fireplace:"Chimenea",
			gameRoom:"Cuarto de juegos",
			garden:"Jardín",
			grill:"Asador",
			guestQuarters:"Cuarto de invitados",
			gym:"Gimnasio",
			homeTheaterRoom:"Home theater",
			indoorsPlayground:"Juegos para niños",
			integratedKitchen:"Cocina integral",
			jacuzzi:"Jacuzzi",
			library:"Biblioteca",
			multiPurposeRoom:"Salón multi propósitos",
			officeRooms:"Despacho",
			pool:"Alberca",
			pub:"Cantina/pub",
			sauna:"Sauna",
			serviceRoom:"Cuarto de servicio",
			spa:"Spa",
			steamRoom:"Cuarto de vapor",
			storage:"Bodega",
			surveillance:"Vigilancia",
			tank:"Tanque de agua",
			terrace:"Terraza",
			wineCellar:"Cava",
			yard:"Patio"
		};

		return function(items){
			var filtered = {};

			for(var index in items){
				if(items.hasOwnProperty(index) && items[index]!=""){
					filtered[labels[index]] = items[index];
				}
			}

			return filtered;
		}
	}
	function favoritesProps(){
		return function (items, favs) {
			var filtered = [];
			for (var i = 0; i < items.length; i++) {
				var item = items[i];

				if (favs.indexOf(item.id)>-1) {
					filtered.push(item);
				}
			}
			return filtered;
		};
	}
	/* DIRECTIVES */
	function lxaScrollNavPropertyList($document, $window, $interval, $timeout){
		return{
			link: link,
			restrict: "A",
			controller: ["$scope", function(scope){
				scope.scrollIsEnable = true;

				scope.$watch('status', function(newValue, oldValue) {
					if(newValue=="showFullDescription"){
						scope.scrollIsEnable = false;
					}else{
						scope.scrollIsEnable = true;
					}
				});
			}]
		};

		function link(scope, element) {
			var scrollThreshold = 75;
			var startY = 0, y = 0;
			var firstY, lastY = 0;
			var startX = 0, x = 0;
			var firstX, lastX = 0;

			var freeToCatchMove;

			var wheelTimeout;

			var loadedLisInterval = $interval(function(){

				console.log("loadedLisInterval ");
				if(element.eq(0).find(".horizontalReel").length>0){
					$interval.cancel(loadedLisInterval);
					fixPosition();
				}
			}, 10);

			scope.$on("filteredPropsHasChanged", function(){
				fixPosition();
			});

			$($window).resize(fixPosition);

			element.eq(0).on('wheel', function(event) {

				if(scope.status==""){
					if(wheelTimeout){
						$timeout.cancel(wheelTimeout);
					}

					wheelTimeout = $timeout(function(){
						if(event.originalEvent.deltaY<4 && scope.currentProperty>0){
							scope.currentPage = 0;
							scope.currentProperty--;
							scope.$apply();
							fixPosition();
						}else if(event.originalEvent.deltaY>4 && scope.currentProperty < (scope.filteredProps.length-1)){
							scope.currentPage = 0;
							scope.currentProperty++;
							scope.$apply();
							fixPosition();
						}
					}, 100);
				}

			});

			element.eq(0).on('touchstart', function(event) {


				if(scope.scrollIsEnable){
					event.preventDefault();

					freeToCatchMove = true;

					element.removeClass("animateTop");

					startY = event.touches[0].pageY - y;
					firstY = event.touches[0].pageY;

					startX = event.touches[0].pageX - x;
					firstX = event.touches[0].pageX;

					$document.eq(0).on('touchmove', mousemove);
					$document.eq(0).on('touchend', mouseup);
				}


			});

			scope.on360ToggleClick = function(){
				if(scope.currentPage==0){
					scope.currentPage = 1;
				}else if(scope.currentPage==1){
					scope.currentPage = 0
				}
				fixPosition();
			};

			function mousemove(event) {
				y = event.touches[0].pageY - startY;
				x = event.touches[0].pageX - startX;

				lastY = event.touches[0].pageY;
				lastX = event.touches[0].pageX;

				var moveThreshold = scrollThreshold/3;
				var isSwipingLeft = firstX>(lastX+moveThreshold);
				var isSwipingRight = firstX<(lastX-moveThreshold);
				var isInFirstPage = scope.currentPage == 0;
				var isInLastPage = scope.currentPage==scope.totalPages;

				if((freeToCatchMove===true || freeToCatchMove == "y") && (firstY>(lastY+moveThreshold) || firstY<(lastY-moveThreshold))){
					freeToCatchMove = "y";
					element.eq(0).css({
						transform: 'translate3d(0,' + y + 'px,0)'
					});
				}else if((freeToCatchMove===true || freeToCatchMove == "x") && (isSwipingLeft || isSwipingRight)){
					//prevenir touchmove si esta en la page 0 o ultima
					if(!(isSwipingRight && isInFirstPage) && !(isSwipingLeft && isInLastPage)){
						freeToCatchMove = "x";
						element.eq(0).find(".currentProperty").find(".horizontalReel").css({
							transform: 'translate3d(' + x + 'px,0,0)'
						});
					}
				}
			}

			function mouseup() {
				if(freeToCatchMove=="y" && firstY > (lastY+scrollThreshold) && scope.currentProperty < (scope.filteredProps.length-1)){
					scope.currentProperty++;
					scope.currentPage = 0;
				}else if(freeToCatchMove=="y" && firstY < (lastY-scrollThreshold) && scope.currentProperty>0){
					scope.currentProperty--;
					scope.currentPage = 0;
				}

				if(freeToCatchMove=="x" && firstX > (lastX+scrollThreshold) && scope.currentPage < scope.totalPages){
					scope.currentPage++;
				}else if(freeToCatchMove=="x" && firstX < (lastX-scrollThreshold) && scope.currentPage>0){
					scope.currentPage--;
				}

				scope.$apply();

				$document.eq(0).off('touchmove', mousemove);
				$document.eq(0).off('touchend', mouseup);

				fixPosition();

			}

			//TODO: hacer dos funciones updatePage y updateProperty para que el codigo correspondiente se ejecuten unicamente al ser necesario
			function fixPosition(){

				console.log("fixPosition, currentPage: " + scope.currentPage);

				element.addClass("animateTop");

				y = (-scope.currentProperty * $window.innerHeight);
				x = (-scope.currentPage * $window.innerWidth);

				element.eq(0).css({
					transform: 'translate3d(0, ' + y + 'px, 0)'
				});

				element.eq(0).find(".currentProperty").find(".horizontalReel").css({
					transform: 'translate3d(' + x + 'px, 0, 0)'
				});
			}
		}
	}

	function lxaProperty($window){
		return{
			restrict: "A",
			templateUrl: 'templates/property-list-item.html',
			link:linkFunction,
			controller:["$scope", controllerFunction]
		};

		function controllerFunction(scope){

			if(scope.$parent.favorites.indexOf(scope.property.id)>-1){
				scope.favoritesToggleBtnText = "Quitar de mi carpeta";
			}else{
				scope.favoritesToggleBtnText = "Guardar en mi carpeta";
			}

			scope.toggleFavoriteStatus = function($id){
				if(scope.$parent.favorites.indexOf($id)==-1){
					scope.$parent.addToFavs($id);
					scope.favoritesToggleBtnText = "Quitar de mi carpeta";
				}else{
					scope.$parent.removeFromFavs($id);
					scope.favoritesToggleBtnText = "Guardar en mi carpeta";
				}
			};
		}

		function linkFunction(scope, element){

			$($window).resize(resize);
			resize();

			function resize(){
				$(element[0]).width($window.innerWidth).height($window.innerHeight);
				$(element[0]).find(".page").width($window.innerWidth);
				$(element[0]).find(".horizontalReel").css({
					transform: 'translate3d(' + (-$window.innerWidth*scope.currentPage) + 'px, 0, 0)',
					width: $window.innerWidth * $(element[0]).find(".page").length
				});

			}
		}
	}
	function lxaTypeSelector(AlertMessage){
		return {
			scope:{
				allOptions:"=",
				formSelectedTypes:"=selectedTypes"
			},
			restrict:"AE",
			template:'<li ng-repeat="(typeCode, typeLabel) in allOptions" value="{{typeCode}}" class="checked">{{typeLabel}}</li>',
			link: linkFunction
		};

		function linkFunction(scope, element){

			element.on("touchstart", "li", function($data){
				var typeValue = $($data.target).attr("value");
				var typeLabel = $($data.target).text();

				if(typeValue in scope.formSelectedTypes){

					if(Object.keys(scope.formSelectedTypes).length>1){
						delete scope.formSelectedTypes[typeValue];
						$($data.target).removeClass("checked");
					}else{
						AlertMessage.show("Debes mantener al menos una opción seleccionada.");
					}

				}else{
					scope.formSelectedTypes[typeValue] = typeLabel;
					$($data.target).addClass("checked");
				}
			});
		}
	}
	function lxaTypeSelectorOption(){
		return {
			scope:{
				label:"=",
				value:"="
			},
			restrict:"AE",
			link: linkFunction
		};

		function linkFunction(scope, element){}
	}
	function lxaMultiSelect($interval) {
		return {
			restrict: 'EA',
			scope: {
				items: "=",
				selectedItems:"="
			},
			template:"" +
				"<div class='selectedNbhdText' hm-tap='openDropdown()'>{{ selectedNeighborhoodText }}</div>" +
				"<button type='button' hm-tap='openDropdown()' ng-hide='isOpen' class='changeNbhdBtn button'>Cambiar</button>" +
				"<div ng-show='isOpen' class='allNbhdListWrp'>" +
					"<div ng-repeat='item in items' class='nbhdItem' ng-class='{checked:item.isSelected}' hm-tap='toggleSelection(item)'>{{item.label}}</div>" +
					"<div class='btnsWrp'>" +
						"<button type='button' class='button' hm-tap='selectAll()'>Todas</button>" +
						"<button type='button' class='button' hm-tap='deselectAll();'>Ninguna</button>" +
						"<button type='button' class='button' hm-tap='closeDropdown()' ng-show='selectedItems.length > 0'>Ok</button>" +
					"</div>" +
				"</div>",
			controller: ["$scope", function ($scope) {
				$scope.isOpen = false;
				$scope.selectedNeighborhoodText = "";

				var loadedInterval = $interval(function(){
					if($scope.items.length>0){
						$interval.cancel(loadedInterval);
						init();
					}
				}, 10);

				function init(){
					for(var j=0; j<$scope.items.length; j++){
						$scope.items[j].isSelected = $scope.selectedItems.indexOf($scope.items[j].code) != -1;
					}
					$scope.applySelection();
				}

				$scope.openDropdown = function () {
					$scope.$parent.changeStatus("showFilterPanel nbhdListOpen");
					$scope.isOpen = true;
				};
				$scope.selectAll = function () {
					for(var i=0; i<$scope.items.length; i++){
						$scope.items[i].isSelected = true;
					}
					$scope.applySelection();
				};
				$scope.deselectAll = function () {
					for(var i=0; i<$scope.items.length; i++){
						$scope.items[i].isSelected = false;
					}
					$scope.applySelection();
				};
				$scope.toggleSelection = function (item) {
					item.isSelected = !item.isSelected;
					$scope.applySelection();
				};
				$scope.applySelection = function () {
					$scope.selectedItems = [];
					$scope.selectedNeighborhoodText = "";
					for(var i=0; i<$scope.items.length; i++){
						if($scope.items[i].isSelected){
							$scope.selectedItems.push($scope.items[i].code);

							if($scope.selectedNeighborhoodText != ""){
								$scope.selectedNeighborhoodText += ", " + $scope.items[i].label;
							}else{
								$scope.selectedNeighborhoodText = $scope.items[i].label;
							}

						}
					}
				};
				$scope.closeDropdown = function(){
					$scope.applySelection();
					$scope.isOpen = false;
					$scope.$parent.changeStatus("showFilterPanel");
				};
			}]
		};
	}
	function lxaVideoLoader() {
		return {
			restrict: 'EA',
			scope: {
				videoUrl: "=",
				poster:"="
			},
			template:
				'<button type="button" hm-tap="toggleVideo()" class="lxaTouchClasses button videoBtn" ng-class="{videoCloseBtn:opened, newButton:opened}"><span>{{buttonLabel}}</span></button>'+
				'<img class="play-shadow" ng-src="img/btn-play-shadow.png">' +
				'<img class="videoPoster" ng-src="{{poster}}">',
			link:linkFunction
		};
		function linkFunction($scope, element){

			$scope.buttonLabel = "REPRODUCIR VIDEO";
			$scope.opened = false;

			$scope.toggleVideo = function(){
				if($scope.opened){
					deleteIframe();
					$scope.$parent.changeStatus("showPropertyList");
					$scope.buttonLabel = "REPRODUCIR VIDEO";
				}else{
					appendIframe();
					$scope.$parent.changeStatus("videoIsActive");
					$scope.buttonLabel = "CERRAR VIDEO";
				}

				$scope.opened = !$scope.opened;

			};

			function deleteIframe(){
				$scope.videoPlayer.remove();
				$scope.videoPlayer = undefined;
			}

			function appendIframe(){
				$scope.videoPlayer = jQuery('<video class="introVideo"><source src="'  + $scope.videoUrl + '"></video>');
				element.append($scope.videoPlayer);

				$scope.videoPlayer.on("touchstart", function($event){
					$event.stopPropagation();
				});

				element.find(".videoBtn").on("touchstart", function($event){
					$event.stopPropagation();
				});
				$scope.videoPlayer.get(0).volume = 0;

				$scope.videoPlayer.get(0).play();
			}
		}
	}
	function lxaMatterportLoader($timeout) {
		return {
			restrict: 'EA',
			scope: {
				matterportUrl: "=matterportUrl",
				poster:"="
			},
			template:
				'<div class="bar-container" ng-hide="hidePreloader">'+
					'<div class="bar"></div>'+
				'</div>'+
				'<button type="button" hm-tap="toggle360()" class="lxaTouchClasses button liveTourBtn" ng-class="{liveTourCloseBtn:opened, newButton:opened}"><span>{{ buttonText }}</span></button>'+
				//'<img class="rightTopBlackBkg" src="img/right-top-black-bkg.png">'+
				//'<img class="play-shadow" ng-src="img/btn-play-shadow.png">' +
				'<img class="liveTourPoster" ng-src="{{poster}}">',
			link:linkFunction
		};

		function linkFunction($scope, element){

			$scope.buttonText = "EXPLORAR INMUEBLE";
			$scope.opened = false;
			$scope.hidePreloader = true;
			$scope.iframeUrl = $scope.matterportUrl + "&help=0&nt=0&play=1&qs=1&brand=0&dh=0&gt=0&hr=0&mls=2&mt=0&lang=es&vr=0";

			$scope.toggle360 = function(){
				if($scope.opened){
					deleteIframe();
					$scope.buttonText = "EXPLORAR INMUEBLE";
					$scope.$parent.changeStatus("showPropertyList");
				}else{
					appendIframe();
					$scope.buttonText = "";
					$scope.$parent.changeStatus("matteportIsActive");
				}

				$scope.opened = !$scope.opened;

			};

			function deleteIframe(){
				$scope.iframe.remove();
			}

			function appendIframe(){
				$scope.iframe = jQuery('<iframe src="' + $scope.iframeUrl + '" class="mp360Iframe" allow="vr" frameborder="0" allowfullscreen></iframe>').fadeOut();
				element.append($scope.iframe);

				element.on("touchstart", function($event){
					if($scope.opened){
						$event.stopPropagation();
					}
				});

				$scope.hidePreloader = false;
				$scope.iframe.on("load", showIframe);
			}

			function showIframe(){
				$timeout(function(){
					$scope.hidePreloader = true;
					$scope.iframe.fadeIn();
				}, 5000);
			}
		}
	}
	function lxaTouchClasses(){
		return {
			restrict: 'C',
			link:linkFunction
		};
		function linkFunction(scope, element){
			element.eq(0).on({
				'touchstart':function() {
					element.addClass("touched");
				},
				'touchend':function() {
					element.removeClass("touched");
				},
				'blur':function() {
					element.removeClass("touched");
				}
			});
		}
	}

})();

if (!Array.prototype.indexOf) {
	Array.prototype.indexOf = function (searchElement /*, fromIndex */ ) {
		"use strict";
		if (this == null) {
			throw new TypeError();
		}
		var t = Object(this);
		var len = t.length >>> 0;
		if (len === 0) {
			return -1;
		}
		var n = 0;
		if (arguments.length > 1) {
			n = Number(arguments[1]);
			if (n != n) { // para verificar si es NaN
				n = 0;
			} else if (n != 0 && n != Infinity && n != -Infinity) {
				n = (n > 0 || -1) * Math.floor(Math.abs(n));
			}
		}
		if (n >= len) {
			return -1;
		}
		var k = n >= 0 ? n : Math.max(len - Math.abs(n), 0);
		for (; k < len; k++) {
			if (k in t && t[k] === searchElement) {
				return k;
			}
		}
		return -1;
	}
}